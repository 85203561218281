import React, { useState } from 'react';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <nav className="bg-white border-gray-200 dark:bg-gray-900 py-2">
            <div className="max-w-screen-xl flex items-center justify-between mx-auto px-4">
                <a href="/">
                    <h1 className="text-2xl font-italic font-medium">asa.</h1>
                </a>

                <button
                    className="md:hidden"
                    onClick={toggleMenu}
                >
                    <span className="sr-only">{isMenuOpen ? 'Close menu' : 'Open menu'}</span>

                    {isMenuOpen ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    )}
                </button>

                <ul className={`md:flex md:items-center ${isMenuOpen ? 'block' : 'hidden'} mt-4 md:mt-0`}>
                    <li className="md:mx-4">
                        <a href="/" className="block text-gray-900 dark:text-white hover:text-gray-500 font-medium">Home</a>
                    </li>
                    <li className="md:mx-4">
                        <a href="/work" className="block text-gray-900 dark:text-white hover:text-gray-500 font-medium">Work</a>
                    </li>
                    <li className="md:mx-4">
                        <a href="/about" className="block text-gray-900 dark:text-white hover:text-gray-500 font-medium">About</a>
                    </li>
                    {/* Sonora AI menünün en sonuna eklenmiştir */}
                    <li className="md:mx-4">
                        <a href="/sonora-ai" className="block text-blue-600 dark:text-yellow-400 hover:text-green-500 font-medium flex items-center">
                            <span className="mr-2">Sonora AI</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Header;
