import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="max-w-screen-md mx-auto p-8 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-300">
            <h1 className="text-3xl font-bold mb-6">Terms and Conditions</h1>

            <p className="text-lg mb-4">
                These Terms and Conditions ("Terms") govern your access to and use of the Sonora AI mobile application, website, and related services (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using the Service.
            </p>

            <h2 className="text-2xl font-semibold mb-4">1. Use of the Service</h2>
            <p className="text-lg mb-4">
                The Sonora AI app provides tools for generating and sharing music using artificial intelligence (AI). You may use the Service only in compliance with these Terms, and all applicable local, state, national, and international laws. Any misuse, unauthorized access, or violation of these Terms may result in immediate termination of your access to the Service.
            </p>

            <h2 className="text-2xl font-semibold mb-4">2. Account Registration and Security</h2>
            <p className="text-lg mb-4">
                To use certain features of the Service, you may be required to register for an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. If you become aware of any unauthorized use of your account, you must notify us immediately.
            </p>

            <h2 className="text-2xl font-semibold mb-4">3. Intellectual Property Rights</h2>
            <p className="text-lg mb-4">
                Sonora AI retains all rights, title, and interest in the Service, including all associated intellectual property rights. You agree not to reproduce, distribute, modify, or create derivative works based on the Service without our express written permission. Any unauthorized use of the Service's intellectual property is strictly prohibited.
            </p>

            <h2 className="text-2xl font-semibold mb-4">4. User-Generated Content</h2>
            <p className="text-lg mb-4">
                As a user of Sonora AI, you may generate or upload content (such as music, compositions, or other media). By using the Service, you grant Sonora AI a worldwide, non-exclusive, royalty-free license to use, distribute, display, and perform the content you create through the platform for the purposes of providing and improving the Service.
            </p>

            <h2 className="text-2xl font-semibold mb-4">5. Privacy and Data Collection</h2>
            <p className="text-lg mb-4">
                We collect and process certain personal data as described in our Privacy Policy. By using the Service, you consent to the collection, use, and sharing of your information as outlined in our Privacy Policy. We may collect your device ID, music data, and other usage information to enhance your experience and improve our service.
            </p>

            <h2 className="text-2xl font-semibold mb-4">6. Termination</h2>
            <p className="text-lg mb-4">
                We reserve the right to terminate or suspend your access to the Service at our discretion, without notice, if we determine that you have violated these Terms or engaged in any unlawful behavior. You may also choose to terminate your account at any time by contacting us at: <a href="mailto:samedakgul99@gmail.com" className="text-blue-500">samedakgul99@gmail.com</a>.
            </p>

            <h2 className="text-2xl font-semibold mb-4">7. Modifications to the Terms</h2>
            <p className="text-lg mb-4">
                We may modify these Terms from time to time, and we will notify users of any significant changes through the Service or by other means. Your continued use of the Service after any such changes constitutes your acceptance of the updated Terms.
            </p>

            <h2 className="text-2xl font-semibold mb-4">8. Disclaimers</h2>
            <p className="text-lg mb-4">
                The Service is provided "as is" and "as available," without warranties of any kind, either express or implied. We do not guarantee that the Service will be available at all times or that it will be free of errors or interruptions. Your use of the Service is at your sole risk.
            </p>

            <h2 className="text-2xl font-semibold mb-4">9. Limitation of Liability</h2>
            <p className="text-lg mb-4">
                To the fullest extent permitted by law, Sonora AI shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Service, even if we have been advised of the possibility of such damages.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-lg mb-4">
                If you have any questions or concerns regarding these Terms, please contact us at: <a href="mailto:samedakgul99@gmail.com" className="text-blue-500">samedakgul99@gmail.com</a>.
            </p>
        </div>
    );
};

export default TermsAndConditions;
