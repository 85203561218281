import React, { useState } from 'react';
import axios from 'axios';

const Faucet = () => {
    const [profileId, setProfileId] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const value = e.target.value;
        if (value.length <= 6) {
            setProfileId(value);
            setIsValid(value.length === 6);
        }
    };

    const handleClick = async () => {
        if (isValid) {
            try {
                const response = await axios.post(`https://sonora-ai-8c2ca83436a1.herokuapp.com/user/grant-free-credit?username=${profileId}`);
                setMessage(response.data.message);
            } catch (error) {
                setMessage(error.response ? error.response.data.message : 'An error occurred');
            }
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
                <h1 className="text-2xl font-bold mb-6 text-center">SONORA Credit Faucet</h1>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="profileId">
                        Enter 6-digit Profile ID
                    </label>
                    <input
                        type="text"
                        id="profileId"
                        value={profileId}
                        onChange={handleChange}
                        placeholder="123456"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <button
                    onClick={handleClick}
                    disabled={!isValid}
                    className={`w-full py-3 px-6 rounded-lg font-semibold text-base transition-all duration-200 transform active:scale-95 shadow-md ${isValid
                            ? 'bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700 text-white shadow-blue-200'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        }`}
                >
                    Take Test Credit
                </button>
                {message && <p className="mt-4 text-center text-red-500">{message}</p>}
            </div>
        </div>
    );
};

export default Faucet;