import React from 'react'

const Footer = () => {
    return (
        <footer className="rounded-lg bg-cream mx-auto md:mx-0">
            <div className="w-full mx-auto max-w-screen-xl py-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                    <a href="/" className="hover:underline text-secondary font-custom2">Abdü Samed Akgül - 2023</a>
                </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-custom1 text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li >
                        <a href="https://github.com/thesammet" className="mr-4 hover:underline md:mr-6 ">Github</a>
                    </li>
                    <li >
                        <a href="https://www.linkedin.com/in/abd%C3%BC-samed-akg%C3%BCl-383906173/" className="mr-4 hover:underline md:mr-6">Linkedin</a>
                    </li>
                    <li >
                        <a href="mailto:samedakgul99@gmail.com" className="hover:underline">Gmail</a>
                    </li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
