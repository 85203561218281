import React from 'react'
import { allWorks } from '../../constants/work'
import WorksList from '../../components/WorkList'

function Works() {
    return (
        <div className="max-w-screen-xl flex flex-col items-center justify-between mx-auto py-10 bg-cream">
            <div className="text-center mb-8">
                <h1 className="text-4xl font-bold text-gray-800 dark:text-white">My Projects</h1>
                <p className="text-m font-custom2 dark:text-white text-main-gray leading-normal md:text-lg md:leading-normal mt-2 ">Take a look at some of my recent projects!</p>
            </div>
            <WorksList items={allWorks} />
        </div>
    )
}

export default Works