import React from 'react'
import { selectedWorks } from '../../constants/work'
import Headline from '../../components/Headline';
import WorksList from '../../components/WorkList'
import { Link } from "react-router-dom";

function Home() {
    return (
        <main class="py-10 bg-cream">
            <Headline />
            <WorksList items={selectedWorks} />
            <div class="pt-12 flex justify-center">
                <Link to="/work" class="bg-secondary rounded p-2 text-cream">
                    See More
                </Link>
            </div>
        </main>
    )
}

export default Home