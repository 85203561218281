import WorkItem from "../WorkItem";
import styles from "./style.module.css";

const changingImages = [0, 2, 9, 10, 12, 13, 14];

const WorksList = ({ items = [] }) => {
    const changingImage = (index) => {
        if (changingImages.includes(index)) {
            return true;
        }
        return false;
    };

    return (
        <div >
            {items.map((work, index) => (
                <WorkItem
                    item={work}
                    index={index}
                    key={index}
                    imageClassName={changingImage(index) && styles.workImage}
                    extra={<></>}
                />
            ))}
        </div>
    );
};

export default WorksList;
