import React from 'react';

const SonoraAIPage = () => {
    return (
        <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-8">
            <div className="max-w-screen-xl mx-auto text-center">
                {/* Başlık */}
                <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-6">Sonora AI - Music Generation App</h1>

                {/* Uygulama hakkında bilgi */}
                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    Sonora AI is a cutting-edge music generation application that allows you to create high-quality music using advanced AI algorithms. Whether you're an experienced musician, a songwriter, or someone who simply enjoys exploring new sounds, Sonora AI offers an intuitive platform where creativity meets technology.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    With its user-friendly interface, Sonora AI makes it easy for anyone to dive into music production without the need for expensive equipment or extensive technical knowledge. The AI-powered engine is designed to understand the nuances of different music genres, allowing users to generate compositions ranging from classical to electronic, jazz, rock, and beyond. Whether you’re in the mood to create soothing ambient tracks or upbeat dance tunes, Sonora AI has you covered.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    One of the most exciting features of Sonora AI is its ability to generate original melodies, harmonies, and rhythms in real-time. You can customize the key, tempo, and instruments used in each composition, making it easy to tailor the music to your exact preferences. If you’re a songwriter looking for inspiration, Sonora AI can provide endless ideas for chord progressions, melodies, and backing tracks.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    For those interested in collaboration, Sonora AI offers tools to share your creations with others. You can easily export your tracks in various formats and collaborate with friends, fellow musicians, or even a global community of users. The app also integrates seamlessly with popular DAWs (Digital Audio Workstations), so you can take your AI-generated music to the next level in your preferred production environment.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    Sonora AI is not just for musicians; it's also perfect for content creators, filmmakers, and game developers who need original background music. With its library of customizable sounds and the ability to generate royalty-free music, you’ll always have the right track for your project. The AI learns from your interactions, so the more you use the app, the more it can tailor its outputs to match your creative vision.
                </p>

                <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                    Additionally, Sonora AI is continually updated with new features and improvements, ensuring that you always have access to the latest tools in AI-driven music creation. Whether you’re composing for a film score, experimenting with new genres, or simply having fun with friends, Sonora AI provides an innovative, accessible, and limitless musical playground.
                </p>

                {/* Google Play ve App Store Yönlendirme */}
                <div className="flex justify-center space-x-4 mb-6">
                    <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="inline-block">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg" alt="Google Play" className="h-16" />
                    </a>
                    <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer" className="inline-block">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/2560px-Download_on_the_App_Store_Badge.svg.png" alt="App Store" className="h-16" />
                    </a>
                </div>

                {/* Privacy Policy, Terms, and EULA */}
                <div className="text-lg text-gray-700 dark:text-gray-300">
                    <p className="mb-4">
                        By using Sonora AI, you agree to our{' '}
                        <a href="/sonora-ai/privacy-policy" className="text-blue-600 dark:text-blue-400 hover:underline">Privacy Policy</a>,{' '}
                        <a href="/sonora-ai/terms-and-conditions" className="text-blue-600 dark:text-blue-400 hover:underline">Terms and Conditions</a>, and{' '}
                        <a href="/sonora-ai/eula" className="text-blue-600 dark:text-blue-400 hover:underline">End-User License Agreement (EULA)</a>.
                    </p>
                </div>

                {/* Footer */}
                <footer className="mt-10 text-gray-600 dark:text-gray-400">
                    <p>© 2024 Sonora AI. All rights reserved.</p>
                </footer>
            </div>
        </div>
    );
};

export default SonoraAIPage;
