import { motion } from "framer-motion";

const Headline = () => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className="text-center px-6 md:px-0"
        >
            <motion.h1
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                className="text-4xl font-custom1 dark:text-white text-main-black leading-tight md:text-5xl md:leading-tighter"
            >
                Hello, I’m Abdü Samed Akgül.
            </motion.h1>
            <motion.p
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }}
                className="text-m font-custom2 dark:text-white text-main-gray leading-normal md:text-lg md:leading-normal mt-6 md:mt-10"
            >
                Welcome to my personal website, a versatile software developer with experience in a variety of programming languages and technologies. With a passion for building mobile apps, backend applications and web applications, I am excited to use my skills to create innovative software solutions. Explore my portfolio and GitHub account to see some of my recent projects, or contact me to learn more about my skills and experience. Let's work together to create something amazing!
            </motion.p>
            <div className="py-8">
                <span className="inline-block w-6 h-6 mr-2">
                    <motion.span
                        initial={{ opacity: 0, rotate: -180 }}
                        animate={{ opacity: 1, rotate: 0 }}
                        transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                        className="inline-block w-6 h-6 mr-2"
                    >
                        <svg className="w-full h-full dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                        </svg>
                    </motion.span>
                </span>
            </div>
        </motion.div>
    );
};

export default Headline;
