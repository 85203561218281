import { useState, useEffect } from "react";
import { allWorks } from "../../constants/work";
import { useParams } from "react-router-dom";
import ForwardBox from "../../components/ForwardBox";
import { ReactComponent as Apple } from "../../assets/icons/apple.svg";
import { ReactComponent as PlayStore } from "../../assets/icons/playstore.svg";
import { ReactComponent as Github } from "../../assets/icons/github.svg";
import { ReactComponent as Web } from "../../assets/icons/web.svg";

const WorkDetail = () => {
    const [work, setWork] = useState(null);

    const { name } = useParams();

    useEffect(() => {
        const work = allWorks.find((work) => work.name === name);
        setWork(work);
    }, [name]);

    return (
        <div className="bg-cream px-4 py-10 md:p-20">
            <div className="max-w-3xl mx-auto">
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <h2 className="text-3xl font-semibold mb-4 md:mb-0">{work?.title}</h2>
                    <div className="flex gap-4">
                        {work?.appStore && (
                            <ForwardBox
                                icon={<Apple width="24" height="24" />}
                                text="App Store"
                                link={work?.appStore}
                            />
                        )}
                        {work?.playStore && (
                            <ForwardBox
                                icon={<PlayStore width="24" height="24" />}
                                text="Play Store"
                                link={work?.playStore}
                            />
                        )}
                        {work?.githubLink && (
                            <ForwardBox
                                icon={<Github width="24" height="24" />}
                                text="Github"
                                link={work?.githubLink}
                            />
                        )}
                        {work?.webLink && (
                            <ForwardBox
                                icon={<Web width="24" height="24" />}
                                text="Web Sitesi"
                                link={work?.webLink}
                            />
                        )}
                    </div>
                </div>
                <p className="mt-4 mb-8">{work?.description}</p>
                <div className="grid gap-8 md:grid-cols-2">
                    <img src={work?.photo1} alt="" className="w-full" />
                    <img src={work?.photo2} alt="" className="w-full" />
                </div>
            </div>
        </div>
    );
};

export default WorkDetail;
