import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Tag from "../Tag";
import styles from "./style.module.css";

const WorkItem = ({ item, extra, className, imageClassName, ...props }) => {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            className={[styles.workBox, className].join(" ")}
            {...props}
        >
            <div className="flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-3/6 md:pr-12 justify-between">
                    <div className="flex flex-col">
                        <span className="text-lg md:text-xl font-custom1 font-extrabold text-secondary pb-3">
                            {props?.index + 1} / {item?.title}
                        </span>
                        <span className="text-xs md:text-sm font-custom2 dark:text-white text-main-black pb-4">
                            {item?.description}
                        </span>
                        <div className="flex flex-row items-center">
                            <span className="text-xs md:text-sm font-custom2 dark:text-white text-main-gray pr-4">
                                Tech Stack:
                            </span>
                            <div className="flex gap-2">
                                {item.tags.map((tag) => (
                                    <Tag tag={tag} />
                                ))}
                                {extra}
                            </div>
                        </div>
                    </div>
                    <a
                        href={item.generalLink}
                        className="text-xs md:text-sm font-custom2 dark:text-white text-main-black underline underline-offset-2 md:underline-offset-2"
                    >
                        More details from this project ↗
                    </a>
                </div>
                <div className="hidden md:flex md:w-3/6 justify-end">
                    <img
                        src={item?.image}
                        className="h-48 md:h-80 w-full md:w-192 object-cover"
                        alt="project image"
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default WorkItem;
