import funlordMobileImage from "../assets/images/funlord/funlord-feature.png";
import turkiyeGenclikOdulleriImage from '../assets/images/turkiye-genclik-odulleri/turkiye-genclik-odulleri-feature.png'
import aybumobileImage from "../assets/images/aybumobile/aybumobile-feature.png";
import finrendMobileImage from "../assets/images/finrend/finrend-feature.png";
import vocanImage from "../assets/images/vocan/vocan-feature.png";
import playlandImage from "../assets/images/playland/playland-feature.png";
import noorImage from "../assets/images/noor/noor-feature.png"
import filminnImage from "../assets/images/filminn/filminn-feature.png";

import eservisPhoto1 from "../assets/images/eservis/photo1.png";
import eservisPhoto2 from "../assets/images/eservis/photo2.png";
import funlordMobilePhoto1 from "../assets/images/funlord/photo1.png";
import funlordMobilePhoto2 from "../assets/images/funlord/photo2.png";
import filminnPhoto1 from "../assets/images/filminn/unnamed (1).webp";
import filminnPhoto2 from "../assets/images/filminn/unnamed (2).webp";
import vocanPhoto1 from "../assets/images/vocan/1.png";
import vocanPhoto2 from "../assets/images/vocan/1242x2208bb.png";
import noorPhoto1 from "../assets/images/noor/1242x2208bb (8).png";
import noorPhoto2 from "../assets/images/noor/1242x2208bb (9).png";
import aybuMobilePhoto1 from "../assets/images/aybumobile/1242x2688bb (1).png";
import aybuMobilePhoto2 from "../assets/images/aybumobile/1242x2688bb (2).png";
import playlandPhoto1 from "../assets/images/playland/1242x2208bb (11).png";
import playlandPhoto2 from "../assets/images/playland/1242x2208bb (12).png";
import tgoPhoto1 from "../assets/images/turkiye-genclik-odulleri/1242x2208bb (14).png";
import tgoPhoto2 from "../assets/images/turkiye-genclik-odulleri/1242x2208bb (15).png";
import finrendPhoto1 from "../assets/images/finrend/1242x2208bb (1).png";
import finrendPhoto2 from "../assets/images/finrend/1242x2208bb (3).png";


export const selectedWorks = [
    {
        name: "vocan",
        image: vocanImage,
        title: "Vocan",
        description:
            "Vocan is a vocabulary learning app designed to help you improve your language skills in a fun and engaging way. Available on both Google Play and the App Store, Vocan offers a variety of features that make language learning easy and enjoyable.With Vocan, you can learn new words and phrases in multiple languages, including English, German, French, and Spanish. The app uses a spaced repetition algorithm that helps you memorize words more effectively and retain them for longer periods of time.The app's user interface is sleek and intuitive, making it easy to navigate and use. Plus, the app is built using modern technologies such as React Native and Node.js, ensuring a smooth and stable user experience.",
        tags: ["React Native", "Node.JS"],
        playStore: "https://play.google.com/store/apps/details?id=com.abdusamedakgul.vocan&hl=tr&gl=US",
        appStore: "https://apps.apple.com/us/app/vocan/id1644353765",
        webLink: "",
        githubLink: "https://github.com/thesammet/vocan",
        generalLink: "/work/vocan",
        photo1: vocanPhoto1,
        photo2: vocanPhoto2
    },
    {
        name: "turkiye-genclik-odulleri",
        image: turkiyeGenclikOdulleriImage,
        title: "Turkiye Genclik Odulleri",
        description:
            "Turkiye Genclik Odulleri is a mobile application designed to support the Youth Awards ceremony held annually in Turkey. The app provides users with an easy way to keep up-to-date with the latest news and updates about the ceremony, as well as access to the award categories and nominees. Generally that app developed for rate the categories and find the best of the category. The app is built using the Flutter framework and uses Django Rest Framework as its backend. It also features push notifications for real-time updates, as well as integration with social media platforms to share and promote the event. Overall, Turkiye Genclik Odulleri is a modern and user-friendly app that enhances the experience of attending the Youth Awards ceremony in Turkey.",
        tags: ["Flutter"],
        playStore: "https://play.google.com/store/apps/details?id=com.valentura.turkiyegenclikodulleri&hl=tr&gl=US",
        appStore: "https://apps.apple.com/us/app/t%C3%BCrkiye-gen%C3%A7lik-%C3%B6d%C3%BClleri/id1615945419",
        webLink: "",
        githubLink: "",
        generalLink: "/work/turkiye-genclik-odulleri",
        photo1: tgoPhoto1,
        photo2: tgoPhoto2
    },
    {
        name: "playland-mobile",
        image: playlandImage,
        title: "Playland Mobil",
        description:
            "Playland is a project that I am involved in as a freelancer. QR Supported payment system (iyzico) for a company that already has entertainment stores in most provinces of Turkey, is a multi-featured mobile application where parents can follow their children in the game.",
        tags: ["React Native"],
        playStore: "https://play.google.com/store/apps/details?id=com.playlandmobile&hl=tr&gl=US",
        appStore: "https://apps.apple.com/tr/app/playland-mobil/id1631854531",
        webLink: "",
        githubLink: "",
        generalLink: "/work/playland-mobile",
        photo1: playlandPhoto1,
        photo2: playlandPhoto2
    },
    {
        name: "funlord-mobile",
        image: funlordMobileImage,
        title: "Funlord Mobil",
        description:
            "Funlord Mobile is a mobile application that offers various entertainment and leisure activities to its users. The application is available for both Android and iOS devices. Funlord Mobile is designed with React Native and uses Django Rest Framework on the server-side. Qr payment solution to play with toys in certain entertainment centers. It hosts the campaigns of entertainment brands. It includes Iyzico payment system.",
        tags: ["React Native"],
        playStore: "https://play.google.com/store/apps/details?id=com.funlord",
        appStore: "https://apps.apple.com/tr/app/funlord-mobil/id6446031864?l=tr",
        webLink: "",
        githubLink: "",
        generalLink: "/work/funlord-mobile",
        photo1: funlordMobilePhoto1,
        photo2: funlordMobilePhoto2
    },
    {
        name: "aybu-mobile",
        image: aybumobileImage,
        title: "Aybu Mobile",
        description:
            "Aybu Mobile is a mobile application developed for Ankara Yıldırım Beyazıt University students and staff. The app allows students to access their syllabus, view the daily restaurant menu. In addition, students can be posted ideas from AYBU Social. The application is developed using React Native technology. Xcode were used for the iOS version building, Android Studio and Java programming language were used for the Android version. On the backend side, Node.js and MongoDB are used. The application communicates with the backend via RESTful APIs and retrieves the data in JSON format. The user interface of the application has been developed using React Native and Redux technologies. Also, the app is powered by tools like Firebase Messaging and Google Analytics.",
        tags: ["React Native", "Node.JS"],
        playStore:
            "https://play.google.com/store/apps/details?id=com.aybumobile&gl=TR",
        appStore:
            "https://apps.apple.com/tr/app/ayb%C3%BC-mobile/id1658659307?l=tr",
        webLink: "",
        githubLink: "https://github.com/thesammet/aybumobile",
        generalLink: "/work/aybu-mobile",
        photo1: aybuMobilePhoto1,
        photo2: aybuMobilePhoto2
    },
    {
        name: "noor-tech",
        image: noorImage,
        title: "Noor Technology",
        description:
            "Noor tech app is an app designed for making its' user's breathing experience better, which works with Noortech’s air purification devices synchronized. Noortech products; keeps the air purifier for you with its’ advanced technology that uses ultraviolet rays. With this app, you can stay informed about the rate of particular matters.  The application is developed using Flutter Framework and using AWS Services connect with application.",
        tags: ["Flutter"],
        playStore:
            "https://play.google.com/store/apps/details?id=com.noortech&hl=tr&gl=US",
        appStore:
            "https://apps.apple.com/us/app/noor-technology/id1626494471",
        webLink: "",
        githubLink: "",
        generalLink: "/work/noor-tech",
        photo1: noorPhoto1,
        photo2: noorPhoto2

    },
    {
        name: "e-servis",
        image: eservisPhoto1,
        title: "E-Servis",
        description:
            "A platform where parents can monitor their children on the school bus along the way, and school management and service companies can easily control and manage the bus. I am working as a Rest Architect.",
        tags: ["Node.js"],
        playStore: "",
        appStore: "",
        webLink: "",
        githubLink: "",
        generalLink: "/work/e-servis",
        photo1: eservisPhoto1,
        photo2: eservisPhoto2
    },
];

export const allWorks = [
    {
        name: "vocan",
        image: vocanImage,
        title: "Vocan",
        description:
            "Vocan is a vocabulary learning app designed to help you improve your language skills in a fun and engaging way. Available on both Google Play and the App Store, Vocan offers a variety of features that make language learning easy and enjoyable.With Vocan, you can learn new words and phrases in multiple languages, including English, German, French, and Spanish. The app uses a spaced repetition algorithm that helps you memorize words more effectively and retain them for longer periods of time.The app's user interface is sleek and intuitive, making it easy to navigate and use. Plus, the app is built using modern technologies such as React Native and Node.js, ensuring a smooth and stable user experience.",
        tags: ["React Native", "Node.JS"],
        playStore: "https://play.google.com/store/apps/details?id=com.abdusamedakgul.vocan&hl=tr&gl=US",
        appStore: "https://apps.apple.com/us/app/vocan/id1644353765",
        webLink: "",
        githubLink: "https://github.com/thesammet/vocan",
        generalLink: "/work/vocan",
        photo1: vocanPhoto1,
        photo2: vocanPhoto2
    },
    {
        name: "turkiye-genclik-odulleri",
        image: turkiyeGenclikOdulleriImage,
        title: "Turkiye Genclik Odulleri",
        description:
            "Turkiye Genclik Odulleri is a mobile application designed to support the Youth Awards ceremony held annually in Turkey. The app provides users with an easy way to keep up-to-date with the latest news and updates about the ceremony, as well as access to the award categories and nominees. Generally that app developed for rate the categories and find the best of the category. The app is built using the Flutter framework and uses Django Rest Framework as its backend. It also features push notifications for real-time updates, as well as integration with social media platforms to share and promote the event. Overall, Turkiye Genclik Odulleri is a modern and user-friendly app that enhances the experience of attending the Youth Awards ceremony in Turkey.",
        tags: ["Flutter"],
        playStore: "https://play.google.com/store/apps/details?id=com.valentura.turkiyegenclikodulleri&hl=tr&gl=US",
        appStore: "https://apps.apple.com/us/app/t%C3%BCrkiye-gen%C3%A7lik-%C3%B6d%C3%BClleri/id1615945419",
        webLink: "",
        githubLink: "",
        generalLink: "/work/turkiye-genclik-odulleri",
        photo1: tgoPhoto1,
        photo2: tgoPhoto2
    },
    {
        name: "playland-mobile",
        image: playlandImage,
        title: "Playland Mobil",
        description:
            "Playland is a project that I am involved in as a freelancer. QR Supported payment system (iyzico) for a company that already has entertainment stores in most provinces of Turkey, is a multi-featured mobile application where parents can follow their children in the game.",
        tags: ["React Native"],
        playStore: "https://play.google.com/store/apps/details?id=com.playlandmobile&hl=tr&gl=US",
        appStore: "https://apps.apple.com/tr/app/playland-mobil/id1631854531",
        webLink: "",
        githubLink: "",
        generalLink: "/work/playland-mobile",
        photo1: playlandPhoto1,
        photo2: playlandPhoto2
    },
    {
        name: "funlord-mobile",
        image: funlordMobileImage,
        title: "Funlord Mobil",
        description:
            "Funlord Mobile is a mobile application that offers various entertainment and leisure activities to its users. The application is available for both Android and iOS devices. Funlord Mobile is designed with React Native and uses Django Rest Framework on the server-side. Qr payment solution to play with toys in certain entertainment centers. It hosts the campaigns of entertainment brands. It includes Iyzico payment system.",
        tags: ["React Native"],
        playStore: "https://play.google.com/store/apps/details?id=com.funlord",
        appStore: "https://apps.apple.com/tr/app/funlord-mobil/id6446031864?l=tr",
        webLink: "",
        githubLink: "",
        generalLink: "/work/funlord-mobile",
        photo1: funlordMobilePhoto1,
        photo2: funlordMobilePhoto2
    },
    {
        name: "aybu-mobile",
        image: aybumobileImage,
        title: "Aybu Mobile",
        description:
            "Aybu Mobile is a mobile application developed for Ankara Yıldırım Beyazıt University students and staff. The app allows students to access their syllabus, view the daily restaurant menu. In addition, students can be posted ideas from AYBU Social. The application is developed using React Native technology. Xcode were used for the iOS version building, Android Studio and Java programming language were used for the Android version. On the backend side, Node.js and MongoDB are used. The application communicates with the backend via RESTful APIs and retrieves the data in JSON format. The user interface of the application has been developed using React Native and Redux technologies. Also, the app is powered by tools like Firebase Messaging and Google Analytics.",
        tags: ["React Native", "Node.JS"],
        playStore:
            "https://play.google.com/store/apps/details?id=com.aybumobile&gl=TR",
        appStore:
            "https://apps.apple.com/tr/app/ayb%C3%BC-mobile/id1658659307?l=tr",
        webLink: "",
        githubLink: "https://github.com/thesammet/aybumobile",
        generalLink: "/work/aybu-mobile",
        photo1: aybuMobilePhoto1,
        photo2: aybuMobilePhoto2
    },
    {
        name: "noor-tech",
        image: noorImage,
        title: "Noor Technology",
        description:
            "Noor tech app is an app designed for making its' user's breathing experience better, which works with Noortech’s air purification devices synchronized. Noortech products; keeps the air purifier for you with its’ advanced technology that uses ultraviolet rays. With this app, you can stay informed about the rate of particular matters.  The application is developed using Flutter Framework and using AWS Services connect with application.",
        tags: ["Flutter"],
        playStore:
            "https://play.google.com/store/apps/details?id=com.noortech&hl=tr&gl=US",
        appStore:
            "https://apps.apple.com/us/app/noor-technology/id1626494471",
        webLink: "",
        githubLink: "",
        generalLink: "/work/noor-tech",
        photo1: noorPhoto1,
        photo2: noorPhoto2

    },
    {
        name: "e-servis",
        image: eservisPhoto1,
        title: "E-Servis",
        description:
            "A platform where parents can monitor their children on the school bus along the way, and school management and service companies can easily control and manage the bus. I am working as a Rest Architect.",
        tags: ["Node.js"],
        playStore: "",
        appStore: "",
        webLink: "",
        githubLink: "",
        generalLink: "/work/e-servis",
        photo1: eservisPhoto1,
        photo2: eservisPhoto2
    },
    {
        name: "finrend",
        image: finrendMobileImage,
        title: "Finrend",
        description:
            "Finrend is a financial application taht followed signals from the stock market and gave recommendations to users.",
        tags: ["React Native"],
        playStore: "https://play.google.com/store/apps/details?id=com.finrend",
        appStore:
            "https://apps.apple.com/gw/app/finrend-your-financial-friend/id1633138818",
        webLink: "http://finrend.com/",
        githubLink: "",
        generalLink: "/work/finrend",
        photo1: finrendPhoto1,
        photo2: finrendPhoto2,
    },
    {
        name: "filminn",
        image: filminnImage,
        title: "Film Inn",
        description: "Filmin is a free movie and TV show review reading app for Android devices. The application is developed using Flutter technology. Firebase Realtime Database was used to store the data. There are also in-app ads and the ability to create users' favorite lists. Users can access various movies and series in the application, and watch them with subtitles or dubbed if they wish.",
        tags: ["Flutter", "Firebase"],
        playStore: "https://play.google.com/store/apps/details?id=com.zaft.filminn&hl=tr&gl=US",
        appStore: "",
        webLink: "https://filminn.vercel.app/",
        githubLink: "https://github.com/thesammet/filminn",
        generalLink: "/work/filminn",
        photo1: filminnPhoto1,
        photo2: filminnPhoto2,
    },
];
