import React from 'react';

const EULA = () => {
    return (
        <div className="max-w-screen-md mx-auto p-8 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-300">
            <h1 className="text-3xl font-bold mb-6">End-User License Agreement (EULA)</h1>

            <p className="text-lg mb-4">
                This End-User License Agreement ("Agreement") is a legal agreement between you ("User") and Sonora AI ("We" or "Our") that governs your use of the Sonora AI mobile application and services. By installing or using Sonora AI, you agree to be bound by this Agreement. If you do not agree to the terms of this Agreement, you must not install or use Sonora AI.
            </p>

            <h2 className="text-2xl font-semibold mb-4">1. License Grant</h2>
            <p className="text-lg mb-4">
                We grant you a limited, non-transferable, revocable license to use Sonora AI on any compatible device that you own or control. You may not rent, lease, or sublicense the app to any third party. This license is for personal use only.
            </p>

            <h2 className="text-2xl font-semibold mb-4">2. License Restrictions</h2>
            <p className="text-lg mb-4">
                You agree not to:
                <ul className="list-disc ml-6">
                    <li>Modify, adapt, translate, or reverse engineer Sonora AI.</li>
                    <li>Use Sonora AI to infringe on the rights of any third party, including intellectual property rights.</li>
                    <li>Attempt to interfere with or disrupt the operation of Sonora AI.</li>
                </ul>
            </p>

            <h2 className="text-2xl font-semibold mb-4">3. Updates and Changes</h2>
            <p className="text-lg mb-4">
                We may update Sonora AI from time to time with new features, bug fixes, or improvements. You agree to install any updates that we provide. Failure to update the app may result in degraded functionality.
            </p>

            <h2 className="text-2xl font-semibold mb-4">4. Data Collection</h2>
            <p className="text-lg mb-4">
                We collect certain data as part of the app's functionality, including device IDs and music-related data. This data is used to improve the performance and features of Sonora AI. For more information, please review our <a href="/sonora-ai/privacy-policy" className="text-blue-500">Privacy Policy</a>.
            </p>

            <h2 className="text-2xl font-semibold mb-4">5. Termination</h2>
            <p className="text-lg mb-4">
                This Agreement is effective until terminated by either you or us. We may terminate the license at any time for violation of these terms. Upon termination, you must cease using Sonora AI and uninstall the app from your device.
            </p>

            <h2 className="text-2xl font-semibold mb-4">6. Limitation of Liability</h2>
            <p className="text-lg mb-4">
                To the maximum extent permitted by law, Sonora AI shall not be liable for any damages resulting from the use or inability to use the app, including but not limited to direct, indirect, incidental, or consequential damages.
            </p>

            <h2 className="text-2xl font-semibold mb-4">7. Governing Law</h2>
            <p className="text-lg mb-4">
                This Agreement shall be governed by and construed in accordance with the laws of the jurisdiction in which Sonora AI operates, without regard to its conflict of law provisions.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="text-lg mb-4">
                If you have any questions about this Agreement or the app, please contact us at: <a href="mailto:samedakgul99@gmail.com" className="text-blue-500">samedakgul99@gmail.com</a>.
            </p>
        </div>
    );
};

export default EULA;
