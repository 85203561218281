import React from 'react';

const PrivacyPolicySharix = () => {
    return (
        <div className="max-w-screen-md mx-auto p-8 bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-300">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

            <p className="text-lg mb-4">
                This Privacy Policy describes how Sharix ("we", "us", or "our") collects, uses, and shares information about you when you use our mobile application.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Information We Collect</h2>
            <p className="text-lg mb-4">
                We collect the following information when you use our app:
            </p>
            <ul className="list-disc ml-6 mb-6">
                <li><strong>Device Information:</strong> We collect your device ID to identify the device using the application.</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">How We Use Your Information</h2>
            <p className="text-lg mb-4">
                The information we collect is used for the following purposes:
            </p>
            <ul className="list-disc ml-6 mb-6">
                <li>To improve the overall performance and user experience of the Sharix app.</li>
                <li>To analyze how users interact with the app for continuous improvement.</li>
                <li>To provide insights and feedback through third-party analytics tools.</li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4">Data Sharing</h2>
            <p className="text-lg mb-4">
                We share user data with third-party analytics services to better understand how our users interact with the app. These services help us improve our features and functionality. No personal information is shared with advertisers or other entities.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Data Retention</h2>
            <p className="text-lg mb-4">
                We store your data for up to 1 month. After that period, the data is automatically deleted unless it's needed for ongoing services or legal obligations.
            </p>

            <h2 className="text-2xl font-semibold mb-4">User Control & Account Deletion</h2>
            <p className="text-lg mb-4">
                Users can request to delete their accounts at any time. Upon request, we will delete the associated data and remove the user from our systems. To request account deletion, contact us at <a href="mailto:samedakgul99@gmail.com" className="text-blue-500">samedakgul99@gmail.com</a>.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Children's Privacy</h2>
            <p className="text-lg mb-4">
                Sharix is designed for children and complies with all relevant privacy regulations. We do not knowingly collect personal information from children without parental consent. If you believe we have unintentionally collected such information, please contact us, and we will take the necessary steps to delete it.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Updates to This Privacy Policy</h2>
            <p className="text-lg mb-4">
                We may update this Privacy Policy from time to time. Any changes will be communicated to users via the app stores (Google Play or App Store), where users will be notified of updates to the policy.
            </p>

            <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
            <p className="text-lg mb-4">
                If you have any questions or concerns about this Privacy Policy or how your data is handled, please contact us at:
                <br />
                Email: <a href="mailto:samedakgul99@gmail.com" className="text-blue-500">samedakgul99@gmail.com</a>
            </p>
        </div>
    );
};

export default PrivacyPolicySharix;
