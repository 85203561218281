import React from 'react'
import { motion } from "framer-motion";
import asa from "../../assets/images/asa.png";
import cv from '../../assets/samedakgul-cv.pdf'
function About() {
    const containerVariants = {
        initial: {
            opacity: 0,
            x: -50
        },
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 1,
                ease: "easeOut"
            }
        }
    };

    const imageVariants = {
        initial: {
            opacity: 0,
            scale: 0.8
        },
        animate: {
            opacity: 1,
            scale: 1,
            transition: {
                delay: 0.1,
                duration: 0.6,
                ease: "easeOut"
            }
        }
    };

    const redirectToPdfFile = () => {
        window.location.href = cv;
    }


    return (
        <div className="py-10 bg-cream mx-8">
            <motion.div
                variants={containerVariants}
                initial="initial"
                animate="animate"
                className="flex flex-col md:flex-row justify-between"
            >
                <div className="flex flex-col md:w-2/5 md:pr-12 justify-around">
                    <div className="flex flex-col">
                        <motion.span variants={imageVariants} className="text-4xl font-custom1 dark:text-white text-main-black">
                            Hi I’m Abdü Samed Akgül,
                        </motion.span>
                        <motion.span variants={imageVariants} className="text-4xl font-custom1 dark:text-white text-main-black">
                            Senior Software Engineer.
                        </motion.span>
                    </div>
                    <div className="flex flex-col pt-4 md:pt-0">
                        <motion.span variants={imageVariants} className="flex text-sm font-custom1 dark:text-white text-main-gray pb-4">
                            As a mobile application developer, I specialize in building native mobile applications for both iOS and Android platforms using React Native. In addition to my mobile development skills, I also have expertise in developing backend applications using Node.js, allowing me to create efficient, scalable solutions using JavaScript. With my experience in both mobile and backend development, I am able to create end-to-end solutions that meet your unique needs. Browse my portfolio to see some of my recent work, or contact me to discuss your next project.
                        </motion.span>
                        <motion.span variants={imageVariants} className="text-sm font-custom1 dark:text-white text-main-gray">
                            Now I’m an freelancer software develoepr in Ankara - currently freelancing and seeking job opportunities.
                        </motion.span>
                    </div>
                </div>
                <motion.div variants={imageVariants} className="flex justify-center items-center pt-4 md:pt-0">
                    <img src={asa} className="h-auto w-full md:h-96 md:w-192 object-contain md:pr-24" alt="project image" />
                </motion.div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.4 }}
            >
                <div className="pt-16">
                    <div className="flex flex-row">
                        <span className="text-sm font-custom2 dark:text-white text-main-black w-40 underline">Main Software:</span>
                        <span className="text-sm font-custom2 dark:text-white text-main-black w-2/6">React Native, Node.JS, Flutter, HTML-CSS, Mongo DB, AWS</span>
                    </div>
                    <div className="flex flex-row pt-8">
                        <span className="text-sm font-custom2 dark:text-white text-main-black w-40 underline">Main Skills:</span>
                        <span className="text-sm font-custom2 dark:text-white text-main-black w-2/6">
                            Programming languages: JavaScript, Dart, HTML<br />
                            Operating systems: Windows, Linux, macOS<br />
                            Software development methodologies: Agile, Waterfall, team collaboration<br />
                            Data structures and algorithms<br />
                            Continuous learning
                        </span>
                    </div>
                </div>
            </motion.div>
            <div class="relative flex pt-24 md:items-center">
                <div class="flex-grow border-t border-gray-400"></div>
            </div>
            <div class="flex flex-col items-center py-8 ">
                <div class="flex-grow border-t border-gray-400"></div>
                <div class="flex flex-col justify-center w-full text-center pt-6">
                    <span class="text-xl font-custom1 dark:text-white text-main-black">I am thrilled to answer to your next project </span>
                </div>
                <div class="flex flex-col justify-center w-full text-center pt-6">
                    <a href="mailto:samedakgul99@gmail.com" class="text-xl font-custom1 text-main-black">samedakgul99@gmail.com</a>
                    <button onClick={redirectToPdfFile} class="text-xs font-custom1 dark:text-white text-main-black underline pt-4">View Resume</button>
                </div>
            </div>


        </div>
    )
}

export default About