import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";

const RootLayout = () => {
    return (
        <main class="flex flex-col h-screen  max-w-7xl mx-auto mb-8 bg-cream">
            <Header class="w-full text-center border-b border-grey p-4 sticky top-0" />
            <main class="flex-1">
                <Outlet />
            </main>
            <Footer class="w-full text-center border-t border-grey p-4 sticky bottom-0" />
        </main>
    )
};

export default RootLayout;